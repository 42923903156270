import * as React from "react";
import "./ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { headlineSmallWith, titleSmallWith } from "../setup/styles-text";

export default function UserHeader({ user }) {
  {
    window.username = user?.name;
  }
  {
    window.email = user?.email;
  }
  {
    window.user = user;
  }
  return (
    <>
      <Card
        align="center"
        sx={{
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            py: "16px",
          }}
        >
          <Typography sx={headlineSmallWith({ color: "#005FAF" })} gutterBottom>
            Welcome, {user?.name}!{console.log(user)}
          </Typography>
          <Typography sx={titleSmallWith({ color: "#414752" })} gutterBottom>
            Your teaching assistant is here to help!
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
