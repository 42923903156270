import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from "react-router-dom";
import GradeGoals from "./GradeGoals";
import Box from "@mui/material/Box";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import LoadingState from "../../components/LoadingState";
import { Preferences } from "@capacitor/preferences";

const GradeUpload = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/`;
  const [data, setData] = useState([]);
  const [photo123, setphoto123] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [gradedata, setGradedata] = useState(false);
  const [imgdata, setImgdata] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setphoto123(URL.createObjectURL(event.target.files[0]));
  };

  const submitImage = () => {
    setImgdata(selectedFile);
  };

  const handleSubmission = async () => {
    var token = await getAccessTokenSilently();
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("topic", 1);
    formData.append("subtopic", 0);
    //show loader

    fetch(
      url +
        `grading?topic=${
          (await Preferences.get({ key: "topic" })).value
        }&subtopic=0`,
      {
        method: "POST",
        body: formData,
      },
      {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    )
      .then((response) => response.json())
      .then((result) => {
        // show results
        setGradedata(result);

        console.log("Success:", result);
      })
      .then((d) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (!idToken) {
    return <Navigate to="/" />;
  }

  return isLoading ? ( //Checkif if is loading
    <LoadingState name=""></LoadingState>
  ) : (
    <div>
      <div className="container-fluid mt-3">
        <div className="card">
          {gradedata ? (
            <div className="card-body">
              <Box sx={{ m: 2 }} style={{ whiteSpace: "pre-wrap" }}>
                {gradedata.data}
              </Box>
            </div>
          ) : !imgdata ? (
            <div>
              <SecondaryAppBar
                name="Upload Image for Grading"
                showBack={true}
              ></SecondaryAppBar>
              <div className="card-body">
                <Box sx={{ m: 5 }} align="center">
                  <Button
                    variant="contained"
                    component="label"
                    style={{ backgroundColor: "#569EF9" }}
                  >
                    Take a Picture of the Paper
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display:"none"}}
                      onChange={changeHandler}
                    />
                  </Button>
                </Box>
                {photo123 ? (
                  <Box align="center">
                    <Box
                      component="img"
                      src={photo123}
                      sx={{ height: "30vh" }}
                    />
                  </Box>
                ) : (
                  ""
                )}

                <Box sx={{ m: 5 }} align="center">
                  <Button
                    size="large"
                    fullWidth={false}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => submitImage()}
                    style={{ backgroundColor: "#569EF9" }}
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>
          ) : (
            <GradeGoals imgd={imgdata} />
          )}
        </div>
      </div>
    </div>
  );
};
export default GradeUpload;
