import React, { useEffect } from "react";
import { App as CapApp } from "@capacitor/app";

import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Curriculum from "./pages/create-common/Curriculum";
import LessonGoals from "./pages/create-lesson/LessonGoals";
import ExamGoals from "./pages/create-exam/ExamGoals";
import GradeGoals from "./pages/create-grading/GradeGoals";
import ReportGoals from "./pages/create-reportcard/ReportGoals";
import LessonOutput from "./pages/create-lesson/LessonOutput";
import ExamOutput from "./pages/create-exam/ExamOutput";
import GradeUpload from "./pages/create-grading/GradeUpload";
import LessonPDF from "./pages/create-lesson/LessonPDF";
import { Capacitor } from "@capacitor/core";
import "./App.scss";
import { Browser } from "@capacitor/browser";
import { useAuth0 } from "@auth0/auth0-react";
import TopAppBar from "./components/todo/TopAppBar";
import { CacheBuster } from "@piplup/cache-buster";
import ReportOutput from "./pages/create-reportcard/ReportOutput";


function App() {
  const { handleRedirectCallback, isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      if (Capacitor.getPlatform() === "ios") {
        await Browser.close();
      }
    });
  }, [handleRedirectCallback]);

  return (
    <>
      <CacheBuster
        loading={null} // Optional: Add a loading component to display loading.
        verbose={false} // Optional: If true, logs will be visible.
        enabled={true}
      />
      <Router>
        <TopAppBar />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="create-lesson/curriculum"
            element={<Curriculum task="createLesson" />}
          />
          <Route path="create-lesson/goals" element={<LessonGoals />} />
          <Route path="create-lesson/output" element={<LessonOutput />} />
          <Route path="create-lesson/pdf" element={<LessonPDF />} />
          <Route
            path="create-exam/curriculum"
            element={<Curriculum task="createExam" />}
          />
          <Route path="create-exam/goals" element={<ExamGoals />} />
          <Route path="create-exam/output" element={<ExamOutput />} />

          <Route
            path="create-grading/curriculum"
            element={<Curriculum task="createGrading" />}
          />
          <Route path="create-grading/upload" element={<GradeUpload />} />
          <Route path="create-grading/goals" element={<GradeGoals />} />
          <Route path="create-grading/output" element={<GradeUpload />} />
          <Route
            path="create-reportcard/curriculum"
            element={<Curriculum task="createReportCard" />}
          />
          <Route path="create-reportcard/goals" element={<ReportGoals />} />
          <Route path="create-reportcard/output" element={<ReportOutput />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
