import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.scss";

var redirect_uri = "";
if (Capacitor.getPlatform() === "ios") {
  console.log("iOS!");
  redirect_uri =
    "com.zozosolutions.zozo://dev-gbm8hjqsotp02kwq.us.auth0.com/capacitor/com.zozosolutions.zozo/callback";
  var useRefreshTokensFallback = false;
} else if (Capacitor.getPlatform() === "android") {
  console.log("Android!");
  redirect_uri =
    "com.zozosolutions.zozo://dev-gbm8hjqsotp02kwq.us.auth0.com/capacitor/com.zozosolutions.zozo/callback";
  var useRefreshTokensFallback = false;
} else {
  console.log("Web!");
  redirect_uri = window.location.origin;
  var useRefreshTokensFallback = true;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="dev-gbm8hjqsotp02kwq.us.auth0.com"
    clientId="FOVi3pqywXdUMIuqzWUTxxTiyNNtfHpz"
    useRefreshTokens={true}
    useRefreshTokensFallback={useRefreshTokensFallback}
    authorizationParams={{
      redirect_uri: redirect_uri,
      audience: "audience",
    }}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,
);

// serviceWorkerRegistration.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
