import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styles from "../../components/ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid"; // Grid version 1
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingState from "../../components/LoadingState";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Preferences } from "@capacitor/preferences";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

const ExamOutput = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      var token = await getAccessTokenSilently();
      fetch(`${process.env.REACT_APP_API_URL}/exam`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          goal: state.goal,
          level: state.level,
          time: state.time,
          noofq: state.activity,
          typeofq: state.assignment,
          pointsperq: "1",
          topic: (await Preferences.get({ key: "topic" })).value,
          subtopic: state.subtopic || 0,
        }),
      })
        .then((response) => response.json())
        .then((d) => {
          try {
            json.stringify(d);
            setData(json.stringify(d));
          } catch {
            setData(d);
          }
        })
        .then((d) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });

      return;
    })();
  }, []);

  const openFeedback = () => {
    Browser.open({
      url: "https://us14.list-manage.com/survey?u=d8fbc33c65fe704844c546dd2&id=7451740cea",
    });
    Browser.addListener("browserFinished", () => {
      navigate("/");
    });
  };

  const onClick = (identifier) => {
    if (identifier == "finish") {
      // open feedback
      openFeedback();
    }
    navigate("/");
  };

  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });
  const items = [];

  return isLoading ? ( //Checkif if is loading
    <LoadingState name="Zozo prepares an Exam according to your goals..." />
  ) : (
    <>
      {data && data.data ? (
        <>
          <SecondaryAppBar name="Exam Output" showBack={true}></SecondaryAppBar>
          <Box sx={{ pb: 8 }}>
            <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
              <div>
                <Card sx={{ marginBottom: "2em" }}>
                  <div>
                    <CardContent>
                      <Grid container direction="row" alignItems="center">
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Grade
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {data.grade}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ borderTop: "0.1em solid #E9E9E9" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Subject
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {data.subject}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{
                          borderTop: "0.1em solid #E9E9E9",
                          borderBottom: "0.1em solid #E9E9E9",
                        }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left",
                            fontWeight: 700,
                          })}
                        >
                          Topic
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#818181",
                            textAlign: "right",
                          })}
                        >
                          {data.topic}
                        </Grid>
                      </Grid>
                      {data.subtopic ? (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          sx={{ borderBottom: "0.1em solid #E9E9E9" }}
                        >
                          <Grid
                            item
                            xs={6}
                            sx={bodyMediumWith({
                              color: "#2A1F1D",
                              textAlign: "left !important",
                              fontWeight: 700,
                            })}
                          >
                            Sub Topic
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={bodyLargeWith({
                              color: "#818181",
                              textAlign: "right",
                            })}
                          >
                            {data.subtopic}
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </div>
                </Card>

                <Card sx={{ marginBottom: "1em" }}>
                  <CardContent>
                    <Typography
                      align="left"
                      sx={bodyMediumWith({
                        color: "#191C21",
                      })}
                      variant="body"
                    >
                      Objective
                    </Typography>
                    <Typography>
                      {state.time} {state.goal} Quiz on topic {data.topic}.{" "}
                      {state.activity} {state.assignment} Questions. Students
                      who score 85% or more show a good level of understanding.
                    </Typography>
                  </CardContent>
                </Card>

                <Card sx={{ marginBottom: "2em" }}>
                  <CardContent>
                    <Typography
                      align="left"
                      sx={bodyMediumWith({
                        color: "#191C21",
                      })}
                      variant="body"
                    >
                      Instructions
                    </Typography>{" "}
                    <Typography>
                      Dear Students, This quiz consists of {state.activity}{" "}
                      multiple-choice questions.
                      <ol>
                        <li>
                          You have {state.time} minutes to complete the quiz.
                        </li>
                        <li>
                          Read each question carefully before selecting your
                          answer.
                        </li>
                        <li>Choose the best option for each question.</li>
                        <li>Good luck!</li>
                      </ol>
                    </Typography>
                  </CardContent>
                </Card>
                {Object.keys(data.data).map((key) => (
                  <Card sx={{ marginBottom: "2em" }}>
                    <CardContent>
                      <Typography
                        align="left"
                        sx={bodyMediumWith({
                          color: "#191C21",
                        })}
                        variant="body"
                      >
                        {data.data[key].question}
                      </Typography>
                      {data.data[key] && data.data[key].options ? (
                        <ol>
                          {Object.keys(data.data[key].options).map(
                            (options) => (
                              <li>{data.data[key].options[options]}</li>
                            ),
                          )}
                        </ol>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                ))}
                <Box sx={{ pt: "1.5em" }}></Box>
                <Box
                  sx={{
                    position: "fixed",
                    left: 0,
                    bottom: "0",
                    width: "100%",
                  }}
                >
                  <Container
                    maxWidth="sm"
                    sx={{ px: "56px", py: "16px", background: "#ffffff" }}
                    textAlign="center"
                  >
                    <Grid
                      container
                      spacing={2}
                      justify="space-between"
                      sx={{ pt: "2em" }}
                    >
                      <Button
                        onClick={() => onClick("finish")}
                        variant="outlined"
                        sx={{
                          boxShadow: "none",
                          py: "1em",
                          mx: "1.5em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#005FAF" }),
                          width: "40%",
                          backgroundColor: "#FFFFFF",
                          borderColor: "#005FAF",
                        }}
                      >
                        Finish
                      </Button>
                      <Button
                        onClick={() => onClick("task")}
                        variant="contained"
                        sx={{
                          boxShadow: "none",

                          py: "1em",
                          mx: "1.5em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#ffffff" }),
                          width: "40%",
                        }}
                      >
                        Keep Working
                      </Button>
                    </Grid>
                  </Container>
                </Box>
              </div>
            </Container>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default ExamOutput;
