import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid"; // Grid version 1
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

export default function SecondaryAppBar({ name, showBack }) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div
      style={{
        borderTop: ".1em solid white",
        boxShadow: ".1em .1em .1em .1em #A8A8A814",
        background: "white",
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={1}>
          {showBack ? <ArrowBackIcon onClick={goBack} /> : ""}
        </Grid>
        <Grid item xs={10} justifyContent="center" display="flex">
          <Typography
            sx={bodyLargeWith({
              color: "#191C21",
              fontWeight: 700,
            })}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
