import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import LoadingState from "../../components/LoadingState";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import { Preferences } from "@capacitor/preferences";
import {
  fetchGradeList,
  fetchSectorList,
  fetchSubjectList,
  fetchSubtopicList,
  fetchTopicList,
} from "../../setup/api";
import {
  bodyLargeWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

const Curriculum = ({ task }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [elementVisible, setElementVisible] = useState('inline-flex');

  const [sectors, setSectors] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);

  const [selectedSector, setSelectedSector] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");

  // Mock fetch functions
  const fetchSectors = async () => {
    let token = await getAccessTokenSilently();
    let result = [];
    await fetchSectorList(
      token,
      (data) => {
        result = data;
      },
      () => {},
      () => {},
      () => {
        setLoading(false);
      },
    );

    return result;
  };


  const fetchGrades = async () => {
    let token = await getAccessTokenSilently();
    let result = [];
    await fetchGradeList(
      token,
      (data) => {
        result = data;
      },
      () => {},
      () => {},
      () => {},
    );

    return result;
  };

  const fetchSubjects = async () => {
    let token = await getAccessTokenSilently();
    let result = [];
    await fetchSubjectList(
      token,
      (data) => {
        result = data;
      },
      () => {},
      () => {},
      () => {},
    );

    return result;
  };

  const fetchTopics = async () => {
    let token = await getAccessTokenSilently();
    let result = [];
    await fetchTopicList(
      token,
      (data) => {
        result = data;
      },
      () => {},
      () => {},
      () => {},
    );

    return result;
  };

  const fetchSubtopics = async () => {
    let token = await getAccessTokenSilently();
    let result = [];
    await fetchSubtopicList(
      token,
      (data) => {
        result = data;
      },
      () => {},
      () => {},
      () => {},
    );

    return result;
  };



  // Similar useEffects can be written for subjects, topics, and subtopics
  // You would use the previous selection to fetch the subsequent list

  useEffect(() => {
    const initializeData = async () => {
      const storedTask = (await Preferences.get({ key: "task" })).value;

      if (storedTask !== task) {
        await Preferences.set({ key: "task", value: task });
      }

      const loadedSectors = await fetchSectors();
      setSectors(loadedSectors);

      const storedSector = (await Preferences.get({ key: "sector" })).value;
      if (
        storedSector &&
        loadedSectors.some((sector) => sector.id.toString() === storedSector)
      ) {
        setSelectedSector(storedSector);

        const loadedGrades = await fetchGrades();
        setGrades(loadedGrades);

        const storedGrade = (await Preferences.get({ key: "grade" })).value;
        if (
          storedGrade &&
          loadedGrades.some((grade) => grade.id.toString() === storedGrade)
        ) {
          setSelectedGrade(storedGrade);

          const loadedSubjects = await fetchSubjects();
          setSubjects(loadedSubjects);

          const storedSubject = (await Preferences.get({ key: "subject" }))
            .value;
          if (
            storedSubject &&
            loadedSubjects.some(
              (subject) => subject.id.toString() === storedSubject,
            )
          ) {
            setSelectedSubject(storedSubject);

            const loadedTopics = await fetchTopics();
            setTopics(loadedTopics);

            const storedTopic = (await Preferences.get({ key: "topic" })).value;
            if (
              storedTopic &&
              loadedTopics.some((topic) => topic.id.toString() === storedTopic)
            ) {
              setSelectedTopic(storedTopic);

              const loadedSubtopics = await fetchSubtopics();
              setSubtopics(loadedSubtopics);

              const storedSubtopic = (
                await Preferences.get({ key: "subtopic" })
              ).value;
              if (
                storedSubtopic &&
                loadedSubtopics.some(
                  (subtopic) => subtopic.id.toString() === storedSubtopic,
                )
              ) {
                setSelectedSubtopic(storedSubtopic);
              }
            }
          }
        }
      }
    };
  if(task=='createReportCard'){
    setElementVisible("none");
  }
    initializeData();
  }, []);

  const onSelectValue = async (
    selectorName,
    selectorValue,
    nextSelectorValues,
  ) => {
    let stored;

    switch (selectorName) {
      case "sector":
        stored = (await Preferences.get({ key: "grade" })).value;
        if (
          nextSelectorValues.length &&
          !(
            stored &&
            nextSelectorValues.some((item) => item.id.toString() === stored)
          )
        ) {
          setSelectedGrade("");
          await Preferences.remove({ key: "grade" });
          setSelectedSubject("");
          await Preferences.remove({ key: "subject" });
          setSelectedTopic("");
          await Preferences.remove({ key: "topic" });
          setSelectedSubtopic("");
          await Preferences.remove({ key: "subtopic" });
        }
        break;
      case "grade":
        stored = (await Preferences.get({ key: "subject" })).value;
        if (
          nextSelectorValues.length &&
          !(
            stored &&
            nextSelectorValues.some((item) => item.id.toString() === stored)
          )
        ) {
          setSelectedSubject("");
          await Preferences.remove({ key: "subject" });
          setSelectedTopic("");
          await Preferences.remove({ key: "topic" });
          setSelectedSubtopic("");
          await Preferences.remove({ key: "subtopic" });
        }
        break;
      case "subject":
        stored = (await Preferences.get({ key: "topic" })).value;
        if (
          nextSelectorValues.length &&
          !(
            stored &&
            nextSelectorValues.some((item) => item.id.toString() === stored)
          )
        ) {
          setSelectedTopic("");
          await Preferences.remove({ key: "topic" });
          setSelectedSubtopic("");
          await Preferences.remove({ key: "subtopic" });
        }
        break;
      case "topic":
        stored = (await Preferences.get({ key: "subtopic" })).value;
        if (
          nextSelectorValues.length &&
          !(
            stored &&
            nextSelectorValues.some((item) => item.id.toString() === stored)
          )
        ) {
          setSelectedSubtopic("");
          await Preferences.remove({ key: "subtopic" });
        }
        break;
      case "subtopic":
        break;
    }
  };

  useEffect(() => {
    const onSelectSector = async () => {
      let loadedGrades = [];
      if (selectedSector) {
        await Preferences.set({ key: "sector", value: selectedSector });
        loadedGrades = await fetchGrades();
        setGrades(loadedGrades);
      }
      await onSelectValue("sector", selectedSector, loadedGrades);
    };

    onSelectSector();
  }, [selectedSector]);

  useEffect(() => {
    const onSelectGrade = async () => {
      let loadedSubjects = [];
      if (selectedGrade) {
        await Preferences.set({ key: "grade", value: selectedGrade });
        loadedSubjects = await fetchSubjects();
        setSubjects(loadedSubjects);
      }
      await onSelectValue("grade", selectedGrade, loadedSubjects);
    };

    onSelectGrade();
  }, [selectedGrade]);

  useEffect(() => {
    const onSelectSubject = async () => {
      let loadedTopics = [];
      if (selectedSubject) {
        await Preferences.set({ key: "subject", value: selectedSubject });
        loadedTopics = await fetchTopics();
        setTopics(loadedTopics);
      }
      await onSelectValue("subject", selectedSubject, loadedTopics);
    };

    onSelectSubject();
  }, [selectedSubject]);

  useEffect(() => {
    const onSelectTopic = async () => {
      let loadedSubtopics = [];
      if (selectedTopic) {
        await Preferences.set({ key: "topic", value: selectedTopic });
        loadedSubtopics = await fetchSubtopics();
        setSubtopics(loadedSubtopics);
      }
      await onSelectValue("topic", selectedTopic, loadedSubtopics);
    };

    onSelectTopic();
  }, [selectedTopic]);

  useEffect(() => {
    const onSelectSubtopic = async () => {
      if (selectedTopic) {
        await Preferences.set({ key: "subtopic", value: selectedSubtopic });
      }
      await onSelectValue("subtopic", selectedSubtopic);
    };

    onSelectSubtopic();
  }, [selectedSubtopic]);

  const onClickContinue = async () => {
    const task = (await Preferences.get({ key: "task" })).value;

    switch (task) {
      case "createLesson":
        navigate("/create-lesson/goals");
        break;
      case "createExam":
        navigate("/create-exam/goals");
        break;
      case "createGrading":
        navigate("/create-grading/upload");
        break;
      case "createReportCard":
        navigate("/create-reportcard/goals");
        break;
    }
  };

  return isLoading ? (
    <LoadingState name="Fetching Sectors"></LoadingState>
  ) : (
    <>
      <SecondaryAppBar name="Curriculum" showBack="true"></SecondaryAppBar>

      <Box sx={{ pb: 8 }}>
        <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
          <Typography sx={headlineLarge} gutterBottom>
            Curriculum
          </Typography>
          <Typography sx={bodyLargeWith({ color: "#414752" })} gutterBottom>
            Pick Grade, Topic and Subtopic of Curriculum for Lesson generation
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel id="sector-label">Sector</InputLabel>
            <Select
              labelId="sector-label"
              id="sector-select"
              value={selectedSector}
              label="Sector"
              sx={{ background: "#ffffff", borderRadius: "8px" }}
              onChange={(event) => setSelectedSector(event.target.value)}
            >
              {sectors.map((sector) => (
                <MenuItem key={sector.id} value={sector.id}>
                  {sector.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" disabled={!selectedSector}>
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select
              labelId="grade-label"
              id="grade-select"
              value={selectedGrade}
              label="Grade"
              sx={{ background: "#ffffff", borderRadius: "8px" }}
              onChange={(event) => setSelectedGrade(event.target.value)}
            >
              {grades.map((grade) => (
                <MenuItem key={grade.id} value={grade.id}>
                  {grade.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" disabled={!selectedGrade} sx={{display:elementVisible}}>
            <InputLabel id="subject-label">Subject</InputLabel>
            <Select
              labelId="subject-label"
              id="subject-select"
              value={selectedSubject}
              label="Subject"
              sx={{ background: "#ffffff", borderRadius: "8px" }}
              onChange={(event) => setSelectedSubject(event.target.value)}
            >
              {subjects.map((subject) => (
                <MenuItem key={subject.id} value={subject.id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        
          <FormControl fullWidth margin="normal" disabled={!selectedSubject} sx={{display:elementVisible}}>
            <InputLabel id="topic-label">Topic</InputLabel>
            <Select
              labelId="topic-label"
              id="topic-select"
              value={selectedTopic}
              label="Topic"
              sx={{ background: "#ffffff", borderRadius: "8px" }}
              onChange={(event) => setSelectedTopic(event.target.value)}
            >
              {topics.map((topic) => (
                <MenuItem key={topic.id} value={topic.id}>
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            
          <FormControl
            fullWidth
            margin="normal"
            disabled={!selectedTopic || subtopics.length === 0}
            sx={{display:elementVisible}}>
          
            <InputLabel id="subtopic-label">Subtopic</InputLabel>
            <Select
              labelId="subtopic-label"
              id="subtopic-select"
              value={selectedSubtopic}
              label="Subtopic"
              sx={{ background: "#ffffff", borderRadius: "8px" }}
              onChange={(event) => setSelectedSubtopic(event.target.value)}
            >
              {subtopics.map((subtopic) => (
                <MenuItem key={subtopic.id} value={subtopic.id}>
                  {subtopic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Container>
        <Box sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
          <Container
            maxWidth="sm"
            sx={{ px: "56px", py: "16px", background: "#ffffff" }}
          >
            <Button
              onClick={() => onClickContinue()}
              variant="contained"
              sx={{
                boxShadow: "none",
                px: "24px",
                py: "16px",
                borderRadius: "8px",
                ...titleMediumWith({ color: "#ffffff" }),
              }}
              fullWidth
              disabled={
                ((!selectedTopic || (subtopics.length > 0 && !selectedSubtopic)) && (task != 'createReportCard'))
              }
            >
              Continue
            </Button>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export default Curriculum;
