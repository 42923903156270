import { Preferences } from "@capacitor/preferences";

export const fetchUser = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(`${process.env.REACT_APP_API_URL}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};

export const fetchSectorList = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(`${process.env.REACT_APP_API_URL}/sectors`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};

export const fetchGradeList = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(
    `${process.env.REACT_APP_API_URL}/grades?sector_id=${
      (await Preferences.get({ key: "sector" })).value
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};

export const fetchSubjectList = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(
    `${process.env.REACT_APP_API_URL}/subjects?grade_id=${
      (await Preferences.get({ key: "grade" })).value
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};

export const fetchTopicList = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(
    `${process.env.REACT_APP_API_URL}/topics?subject_id=${
      (await Preferences.get({ key: "subject" })).value
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};

export const fetchSubtopicList = async (
  token,
  onSuccess,
  onError,
  onLoadingStart,
  onLoadingEnd,
) => {
  onLoadingStart();

  await fetch(
    `${process.env.REACT_APP_API_URL}/subtopics?topic_id=${
      (await Preferences.get({ key: "topic" })).value
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      onLoadingEnd();
      onSuccess(data);
    })
    .catch((error) => {
      onLoadingEnd();
      onError(error);
    });
};
