import _ from "lodash";

export const displayLarge = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "57px",
  lineHeight: "64px",
  letterSpacing: "-0.25px",
  textAlign: "left",
  color: "#191C21",
};
export const displayLargeWith = (changes) => _.merge({}, displayLarge, changes);

export const displayMedium = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "45px",
  lineHeight: "52px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const displayMediumWith = (changes) =>
  _.merge({}, displayMedium, changes);

export const displaySmall = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "36px",
  lineHeight: "44px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const displaySmallWith = (changes) => _.merge({}, displaySmall, changes);

export const headlineLarge = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "32px",
  lineHeight: "40px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const headlineLargeWith = (changes) =>
  _.merge({}, headlineLarge, changes);

export const headlineMedium = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const headlineMediumWith = (changes) =>
  _.merge({}, headlineMedium, changes);

export const headlineSmall = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const headlineSmallWith = (changes) =>
  _.merge({}, headlineSmall, changes);

export const titleLarge = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "22px",
  lineHeight: "28px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#191C21",
};
export const titleLargeWith = (changes) => _.merge({}, titleLarge, changes);

export const titleMedium = {
  fontFamily: "Lato",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "left",
  color: "#191C21",
};
export const titleMediumWith = (changes) => _.merge({}, titleMedium, changes);

export const titleSmall = {
  fontFamily: "Lato",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  textAlign: "left",
  color: "#191C21",
};
export const titleSmallWith = (changes) => _.merge({}, titleSmall, changes);

export const bodyLarge = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  textAlign: "left",
  color: "#191C21",
};
export const bodyLargeWith = (changes) => _.merge({}, bodyLarge, changes);

export const bodyMedium = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.25px",
  textAlign: "left",
  color: "#191C21",
};
export const bodyMediumWith = (changes) => _.merge({}, bodyMedium, changes);

export const bodySmall = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.4px",
  textAlign: "left",
  color: "#191C21",
};
export const bodySmallWith = (changes) => _.merge({}, bodySmall, changes);

export const labelLarge = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  textAlign: "left",
  color: "#191C21",
};
export const labelLargeWith = (changes) => _.merge({}, labelLarge, changes);

export const labelMedium = {
  fontFamily: "Lato",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.5px",
  textAlign: "left",
  color: "#191C21",
};
export const labelMediumWith = (changes) => _.merge({}, labelMedium, changes);

export const labelSmall = {
  fontFamily: "Lato",
  fontWeight: "400",
  fontSize: "11px",
  lineHeight: "16px",
  letterSpacing: "0.5px",
  textAlign: "left",
  color: "#191C21",
};
export const labelSmallWith = (changes) => _.merge({}, labelSmall, changes);

// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
