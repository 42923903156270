import React from "react";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CreateCard from "../components/CreateCard";
import HistoryCard from "../components/HistoryCard";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import "plyr-react/plyr.css";
import { titleMedium } from "../setup/styles-text";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import LoadingState from "../components/LoadingState";
import { Preferences } from "@capacitor/preferences";
import UserHeader from "../components/UserHeader";

const Home = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const login = async () => {
    await loginWithRedirect({
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: "_self",
        });
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const onClickTask = (identifier) => {
    Preferences.set({
      key: "task",
      value: identifier,
    });

    switch (identifier) {
      case "createLesson":
        navigate("/create-lesson/curriculum");
        break;
      case "createExam":
        navigate("/create-exam/curriculum");
        break;
      case "createGrading":
        navigate("/create-grading/curriculum");
        break;
      case "myLessons":
      case "myStudentsGrades":
        break;
      case "createReportCard":
        navigate("/create-reportcard/curriculum");
        break;
        break;
    }
  };

  if (isLoading) {
    return <LoadingState />;
  }

  if (!isLoading && !isAuthenticated) {
    login("/");
  } 

  return (
    <div className="container-fluid mt-3">
      <div className="card">
        <div className="card-body">
          {!isLoading && !isAuthenticated ? (
            <div key="video" style={{ height: "50%" }}>
              <Typography align="center" style={{ paddingTop: "7vh" }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => login("/")}
                  style={{ backgroundColor: "#569EF9" }}
                >
                  Start your Journey
                </Button>
              </Typography>
            </div>
          ) : (
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Private Beta Program
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Dear {user?.name},<br></br> To access this feature, Please
                    visit{" "}
                    <a
                      href="https://www.zozosolutions.com/apply"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ZOZO Solutions
                    </a>{" "}
                    and Apply for Beta Access.
                  </Typography>
                </Box>
              </Modal>
              <UserHeader user={user} />
              <Card
                align="center"
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: 0,
                  }}
                >
                  <Typography sx={{ ...titleMedium }} gutterBottom>
                    What would you like to do?
                  </Typography>
                </CardContent>
                <div
                  key="createLesson"
                  onClick={() => onClickTask("createLesson")}
                >
                  <CreateCard
                    name="Create a Lesson Plan"
                    iconname="assets/images/createlessonemoji.png"
                  ></CreateCard>
                </div>
                <div key="createExam" onClick={() => onClickTask("createExam")}>
                  <CreateCard
                    name="Create an Exam"
                    iconname="assets/images/createexamemoji.png"
                  ></CreateCard>
                </div>
                <div
                  key="createGrading"
                  onClick={() => onClickTask("createGrading")}
                >
                  <CreateCard
                    name="Create a Grading"
                    iconname="assets/images/creategradingemoji.png"
                  ></CreateCard>
                </div>
                <div
                  key="createReportCard"
                  onClick={() => onClickTask("createReportCard")}
                >
                  <CreateCard
                    name="Create a Report Card"
                    iconname="assets/images/creategradingemoji.png"
                  ></CreateCard>
                </div>
              </Card>
              <Card
                align="center"
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: 0,
                  }}
                >
                  <Typography sx={{ ...titleMedium }} gutterBottom>
                    History
                  </Typography>
                </CardContent>
                <div key="myLessons" onClick={() => onClickTask("myLessons")}>
                  <HistoryCard name="My Lessons (coming soon)"></HistoryCard>
                </div>
                <div
                  key="myStudentsGrades"
                  onClick={() => onClickTask("myStudentsGrades")}
                >
                  <HistoryCard name="My Students Grades (coming soon)"></HistoryCard>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Home;
