import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styles from "../../components/ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid"; // Grid version 1
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingState from "../../components/LoadingState";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Preferences } from "@capacitor/preferences";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyDocument from "./LessonPDF";
import { usePDF } from 'react-to-pdf';

import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

const LessonOutput = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  var passobj;
  var worksheetinput = false
  useEffect(() => {
    (async () => {
      
      var token = await getAccessTokenSilently();
      if (!state.oldplan) {
        
      if(state.worksheet=="true"){
        worksheetinput = true;
      }
        fetch(`${process.env.REACT_APP_API_URL}/lessonplans`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            goal: state.goal,
            level: state.level,
            time: state.time,
            activitytype: state.activity,
            assignmenttype: state.assignment,
            topic: (await Preferences.get({ key: "topic" })).value,
            subtopic: state.subtopic || 0,
            worksheet: worksheetinput?'true':'false'
          }),
        })
          .then((response) => response.json())
          .then((d) => {
            try {
              json.stringify(d);
              setData(json.stringify(d));
              passobj.grade  = d.grade;
            } catch {
              setData(d);
            }
            
          })
          .then((d) => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });

        return;
      } else {
        console.log(state.oldplan.data.Objective);
        setData(JSON.stringify(state.oldplan));
        setLoading(false);
      }
    })();
  }, []);

  const openFeedback = () => {
    Browser.open({
      url: "https://us14.list-manage.com/survey?u=d8fbc33c65fe704844c546dd2&id=7451740cea",
    });
    Browser.addListener("browserFinished", () => {
      navigate("/");
    });
  };

  const onClick = (identifier) => {
    if (identifier == "finish") {
      openFeedback();
    }
    navigate("/");
  };

  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });

  const items = [
    "Introduction",
    "Whiteboard Example",
    "Activity",
    "Assignment",
    "Conclusion",
  ];


  return isLoading ? ( //Checkif if is loading
    <LoadingState name="Zozo is preparing a lesson according to your goals..." />
  ) : (
    <>
      <div>
        <SecondaryAppBar name="Lesson Output" showBack={true} ></SecondaryAppBar>
        {data && data.data && data.data.Objective ? (
          <Box sx={{ pb: 8 }}>
            <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
              <div>
                <Card sx={{ marginBottom: "2em" }}>
                  <div>
                    <CardContent>
                      
                    {/* <PDFDownloadLink document={<MyDocument grade={data.grade} subject={data.subject} topic={data.topic}  />} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Download PDF' : 'Download PDF')}
      </PDFDownloadLink>                      */}
      
      
       <Grid container direction="row" alignItems="center">
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Grade
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {data.grade}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ borderTop: "0.1em solid #E9E9E9" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Subject
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {data.subject}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{
                          borderTop: "0.1em solid #E9E9E9",
                          borderBottom: "0.1em solid #E9E9E9",
                        }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left",
                            fontWeight: 700,
                          })}
                        >
                          Topic
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#818181",
                            textAlign: "right",
                          })}
                        >
                          {data.topic}
                        </Grid>
                      </Grid>
                      {data.subtopic ? (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          sx={{ borderBottom: "0.1em solid #E9E9E9" }}
                        >
                          <Grid
                            item
                            xs={6}
                            sx={bodyMediumWith({
                              color: "#2A1F1D",
                              textAlign: "left !important",
                              fontWeight: 700,
                            })}
                          >
                            Sub Topic
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={bodyLargeWith({
                              color: "#818181",
                              textAlign: "right",
                            })}
                          >
                            {data.subtopic}
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </div>
                </Card>
                <Box sx={{ marginBottom: "1em" }}>
                  <div align="left">
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                        <Typography
                          sx={bodyLargeWith({
                            color: "#191C21",
                          })}
                        >
                          {data.data["Objective"].name}:
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {data.data["Objective"].content}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Box>
                {items.map((item, index) => (
                  <Box sx={{ marginBottom: "1em" }}>
                    <div align="left" sx={{ marginTop: "1em" }}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                          <Typography
                            sx={bodyLargeWith({
                              color: "#191C21",
                            })}
                          >
                            {data.data[item].name} ({data.data[item].time}):
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{data.data[item].content}</Typography>            
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Box>
                ))}
                {
                  data.worksheet ? (
                    <Box sx={{ marginBottom: "1em" }}>
                    <div align="left" sx={{ marginTop: "1em" }}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                          <Typography
                            sx={bodyLargeWith({
                              color: "#191C21",
                            })}
                          >
                            WorkSheet
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {data.worksheet.id.map((item, index) => (
                          <Typography>{index+1}.&nbsp;{data.worksheet.id[index].question}</Typography>   
                        ))}         
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Box>

                  ):("")
                }
<br></br>
<br></br>
<br></br>

                <Box
                  sx={{
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    width: "100%",
                  }}
                >
                  <Container
                    maxWidth="sm"
                    sx={{
                      px: "56px",
                      py: "16px",
                      background: "#ffffff",
                      pt: "3em",
                      my:"0"
              
                    }}
                    textAlign="center"
                  >
                    <Grid container spacing={2} justify="space-between">
                      <Button
                        onClick={() => onClick("finish")}
                        variant="outlined"
                        sx={{
                          boxShadow: "none",
                          py: "1em",
                          mx: "1.5em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#005FAF" }),
                          width: "40%",
                          backgroundColor: "#FFFFFF",
                          borderColor: "#005FAF",
                        }}
                      >
                        Finish
                      </Button>
                      <Button
                        onClick={() => onClick("task")}
                        variant="contained"
                        sx={{
                          boxShadow: "none",

                          py: "1em",
                          mx: "1.5em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#ffffff" }),
                          width: "40%",
                        }}
                      >
                        Keep Working
                      </Button>
                    </Grid>
                  </Container>
                </Box>
              </div>
            </Container>
          </Box>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default LessonOutput;
