import * as React from "react";
import "./ButtonStyle.module.css";
import Typography from "@mui/material/Typography";

import { animated, useSpring } from "@react-spring/web";
import { headlineSmall, titleMediumWith } from "../setup/styles-text";
import Box from "@mui/material/Box";

export default function LoadingState({ name }) {
  const bounce = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.05)" },
    config: { duration: 750 },
    loop: { reverse: true },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <animated.img
        style={bounce}
        src="/assets/images/image-removebg.png"
        height="200rem"
        alt=""
      />
      <Typography sx={headlineSmall} gutterBottom>
        Loading
      </Typography>
      {name && (
        <Typography sx={titleMediumWith({ color: "#414752" })} gutterBottom>
          {name}
        </Typography>
      )}
    </Box>
  );
}
