import * as React from "react";
import "./ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid"; // Grid version 1
import { titleMediumWith } from "../setup/styles-text";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function HistoryCard({ name }) {
  return (
    <Card
      sx={{
        minWidth: 100,
        background: "white",
        m: 2,
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "8px",
        boxShadow: "none",
      }}
    >
      <CardContent sx={{ "&:last-child": { pb: "12px" }, padding: "12px" }}>
        <Grid container direction="row">
          <Grid item xs={2} sm={1}>
            <IconButton style={{ color: "#569EF9" }}>
              <FolderIcon fontSize="14px" />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={9}
            sm={10}
            justifyContent="center"
            display="table-cell"
            textAlign="left"
            margin="auto"
          >
            <Typography
              style={{ color: "#22415a", padding: "0 .5em" }}
              sx={titleMediumWith({ color: "#ffffff" })}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={1} justifyContent="center" display="flex">
            <IconButton style={{ color: "#191C21" }}>
              <ArrowForwardIosIcon fontSize="14px" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
