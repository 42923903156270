import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";

export default function TopAppBar() {
  var logoutUri = "";
  const {  user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout } = useAuth0();

  if (Capacitor.getPlatform() === "ios") {
    console.log("iOS!");
    logoutUri =
      "com.zozosolutions.zozo://dev-gbm8hjqsotp02kwq.us.auth0.com/capacitor/com.zozosolutions.zozo/callback";
  } else if (Capacitor.getPlatform() === "android") {
    console.log("Android!");
    logoutUri =
      "com.zozosolutions.zozo://dev-gbm8hjqsotp02kwq.us.auth0.com/capacitor/com.zozosolutions.zozo/callback";
  } else {
    console.log("Web!");
    logoutUri = window.location.origin;
  }

  const login = async () => {
    await loginWithRedirect({
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: "_self",
        });
      },
    });
  };

  if (!isLoading && !isAuthenticated) {
    login("/");
  } 

  const doLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: logoutUri,
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: "_self",
        });
      },
    });
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "white" }}>
          <Toolbar>
            <Box
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                backgroundImage: `url("/zozoLogo.svg")`,
                backgroundRepeat: "no-repeat",
                height: "2em",
                width: "10em",
                display: "flex",
                justifyContent: "center",
              }}
            ></Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
