import * as React from "react";
import "./ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid"; // Grid version 1
import Icon from "@mui/material/Icon";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddIcon from "@mui/icons-material/Add";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { decode } from "html-entities";
import { titleMediumWith } from "../setup/styles-text";

export default function CreateCard({ name, iconname }) {
  const components = {
    AssignmentTurnedInIcon: AssignmentTurnedInIcon,
    ContentPasteSearchIcon: ContentPasteSearchIcon,
    AssignmentIndIcon: AssignmentIndIcon,
  };

  function LoadIcon(iconname) {
    {
      if (iconname.includes(".")) {
        return <img src={iconname} height="35px" width="35px" alt="logo" />;
      } else if (iconname.includes("&#")) {
        return <div>{decode(iconname)}</div>;
      } else {
        const Icon = components[iconname];
        return <Icon fontSize="large" />;
      }
    }
  }

  return (
    <Card
      sx={{
        minWidth: 100,
        background: "#569EF9",
        m: 2,
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "8px",
        boxShadow: "none",
      }}
    >
      <CardContent
        sx={{ "&:last-child": { pb: "14px" }, px: "20px", py: "14px" }}
      >
        <Grid container direction="row">
          <Grid item xs={2} sm={1}>
            <IconButton
              style={{
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                color: "#ffffff",
              }}
            >
              {LoadIcon(iconname)}
            </IconButton>
          </Grid>
          <Grid
            item
            xs={9}
            sm={10}
            justifyContent="center"
            display="table-cell"
            textAlign="left"
            margin="auto"
          >
            <Typography
              style={{ padding: "0 .5em" }}
              sx={titleMediumWith({ color: "#ffffff" })}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={1} justifyContent="center" display="flex">
            <IconButton style={{ color: "#ffffff" }}>
              <AddIcon fontSize="14px" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
