import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styles from "../../components/ButtonStyle.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid"; // Grid version 1
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingState from "../../components/LoadingState";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Preferences } from "@capacitor/preferences";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

const ReportOutput = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      var token = await getAccessTokenSilently();
      fetch(`${process.env.REACT_APP_API_URL}/reportcard`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          gender: state.gender,
          length: state.length,
          tone: state.tone,
          toinclude: state.toinclude,
          social: state.social,
          academic: state.academic,
          emotional: state.emotional,
        }),
      })
        .then((response) => response.json())
        .then((d) => {
          try {
            console.log(d);
            setData(d);
          } catch {
            setData(d);
          }
        })
        .then((d) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });

      return;
    })();
  }, []);

  const openFeedback = () => {
    Browser.open({
      url: "https://us14.list-manage.com/survey?u=d8fbc33c65fe704844c546dd2&id=7451740cea",
    });
    Browser.addListener("browserFinished", () => {
      navigate("/");
    });
  };

  const onClick = (identifier) => {
    if (identifier == "finish") {
      // open feedback
      openFeedback();
    }
    navigate("/");
  };

  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });
  const items = [];

  return isLoading ? ( //Checkif if is loading
    <LoadingState name="Zozo prepares an Report Card according to your goals..." />
  ) : (
    <>
      {data && data.data ? (
        <>
          <SecondaryAppBar
            name="Report Card Output"
            showBack={true}
          ></SecondaryAppBar>
          <Box sx={{ pb: 8 }}>
            <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
              <div>
                <Card sx={{ marginBottom: "2em" }}>
                  <div>
                    <CardContent>
                      <Grid container direction="row" alignItems="center">
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Gender
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {state.gender}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ borderTop: "0.1em solid #E9E9E9" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={bodyMediumWith({
                            color: "#2A1F1D",
                            textAlign: "left !important",
                            fontWeight: 700,
                          })}
                        >
                          Length
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={bodyLargeWith({
                            color: "#2A1F1D",
                            textAlign: "right",
                          })}
                        >
                          {state.length}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </div>
                </Card>

                <Card sx={{ marginBottom: "2em" }}>
                  <CardContent>
                    {/* <Typography
                      align="left"
                      sx={bodyMediumWith({
                        color: "#191C21",
                      })}
                      variant="body"
                    >
                      Instructions
                    </Typography>{" "} */}
                    <Typography>{data.data}</Typography>
                  </CardContent>
                </Card>
                <Box sx={{ pt: "1.5em" }}></Box>
                <Box
                  sx={{
                    position: "fixed",
                    left: 0,
                    bottom: "0",
                    width: "100%",
                  }}
                >
                  <Container
                    maxWidth="sm"
                    sx={{ px: "56px", py: "16px", background: "#ffffff" }}
                    textAlign="center"
                  >
                    <Grid
                      container
                      spacing={2}
                      justify="space-between"
                      sx={{ pt: "2em" }}
                    >
                      <Button
                        onClick={() => onClick("finish")}
                        variant="outlined"
                        sx={{
                          boxShadow: "none",
                          py: "1em",
                          mx: "1em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#005FAF" }),
                          width: "35%",
                          backgroundColor: "#FFFFFF",
                          borderColor: "#005FAF",
                        }}
                      >
                        Finish
                      </Button>
                      <Button
                        onClick={() => onClick("task")}
                        variant="contained"
                        sx={{
                          boxShadow: "none",

                          py: "1em",
                          mx: "1em",
                          borderRadius: "8px",
                          ...titleMediumWith({ color: "#ffffff" }),
                          width: "35%",
                        }}
                      >
                        Keep Working
                      </Button>
                    </Grid>
                  </Container>
                </Box>
              </div>
            </Container>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default ReportOutput;
