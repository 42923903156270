import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styles from "../../components/ButtonStyle.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import LoadingState from "../../components/LoadingState";
import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import { Browser } from "@capacitor/browser";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Preferences } from "@capacitor/preferences";
import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ReportGoals = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/`;

  const [proper, setProper] = React.useState("");

  const [isLoading, setLoading] = useState(false);

  const [formats, setFormats] = React.useState(() => []);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const [tone, setTone] = React.useState(() => ["Objective"]);

  const handleTone = (event, newTones) => {
    setTone(newTones);
  };

  const [academic, setAcademic] = React.useState(() => ["Curious"]);

  const handleAcademic = (event, newTones) => {
    setAcademic(newTones);
  };

  const [emotional, setEmotional] = React.useState(() => ["Empathetic"]);

  const handleEmotional = (event, newTones) => {
    setEmotional(newTones);
  };

  const [toinclude, setToinclude] = React.useState(() => ["Social"]);

  const handleToinclude = (event, newTones) => {
    setToinclude(newTones);
  };

  const [social, setSocial] = React.useState(() => ["Communicative"]);

  const handleSocial = (event, newTones) => {
    setSocial(newTones);
  };

  const [length, setLength] = React.useState("Brief");

  const handleLength = (event, newLength) => {
    if (length !== null) {
      setLength(newLength);
    }
  };

  const [goal, setGoal] = React.useState("Male");

  const handleGoal = (event, newGoal) => {
    if (goal !== null) {
      setGoal(newGoal);
    }
  };

  const checkLocalStorage = async (store) => {
    if (!(await Preferences.get({ key: store })).value) {
      navigate(`/create-reportcard/goals`);
      return (await Preferences.get({ key: store })).value;
    }
  };

  useEffect(() => {
    const init = async () => {
      await checkLocalStorage("task");
      await checkLocalStorage("sector");
      await checkLocalStorage("grade");
      await checkLocalStorage("subject");
    };

    init();
    setProper(props);
  }, []);

  if (!idToken) {
    return <Navigate to="/" />;
  }
  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onClickSubmit = async () => {
    const subtopic = (await checkLocalStorage("subtopic", 1)) || "";
    navigate("/create-reportcard/output", {
      state: {
        gender: goal,
        length: length,
        tone: tone,
        toinclude: toinclude,
        social: social,
        academic: academic,
        emotional: emotional,
      },
    });
  };

  return isLoading ? ( //Checkif if is loading
    <LoadingState name=""></LoadingState>
  ) : (
    <>
      (
      <div>
        <SecondaryAppBar
          name="Set Goals for Report Card"
          showBack={true}
        ></SecondaryAppBar>
        <Box sx={{ pb: 8 }}>
          <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
            <Typography sx={headlineLarge} gutterBottom>
              Goals
            </Typography>
            <Typography sx={bodyLargeWith({ color: "#414752" })} gutterBottom>
              Grade student work based on your grading style & student
              characteristics
            </Typography>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Gender
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="gender"
                    value={goal}
                    exclusive
                    onChange={handleGoal}
                    aria-label="text alignment"
                    orientation="horizontal"
                    sx={{ ml: "-2em" }}
                  >
                    <ToggleButton
                      value="Male"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Male
                    </ToggleButton>

                    <ToggleButton
                      value="Female"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Female
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Length
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="length"
                    value={length}
                    exclusive
                    onChange={handleLength}
                    aria-label="text alignment"
                    orientation="horizontal"
                    sx={{ ml: "-2em" }}
                  >
                    <ToggleButton
                      value="Brief"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Brief
                    </ToggleButton>

                    <ToggleButton
                      value="Detailed"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Detailed
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Tone of Voice
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="level"
                    value={tone}
                    onChange={handleTone}
                    aria-label="text alignment"
                    orientation="horizontal"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gridGap: "1em",
                      margin: "1em 0em",
                      padding: "0em",
                    }}
                  >
                    <ToggleButton
                      value="Objective"
                      sx={bodySmallWith({ margin: "1em 1em 1em 0em" })}
                    >
                      Objective
                    </ToggleButton>

                    <ToggleButton
                      value="Constructive"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Constructive
                    </ToggleButton>

                    <ToggleButton
                      value="Encouraging"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Encouraging
                    </ToggleButton>

                    <ToggleButton
                      value="Supportive"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Supportive
                    </ToggleButton>

                    <ToggleButton
                      value="Diplomatic"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Diplomatic
                    </ToggleButton>
                    <ToggleButton
                      value="Assertive "
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Assertive
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Report Include
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="reportinclude"
                    value={toinclude}
                    onChange={handleToinclude}
                    aria-label="text alignment"
                    orientation="horizontal"
                  >
                    <ToggleButton
                      value="Social"
                      sx={bodySmallWith({ margin: "1em 1em 1em 0em" })}
                    >
                      Social
                    </ToggleButton>

                    <ToggleButton
                      value="Academic"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Academic
                    </ToggleButton>

                    <ToggleButton
                      value="Emotional"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Emotional
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Social Assessment
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="handleSocial"
                    value={social}
                    onChange={handleSocial}
                    aria-label="text alignment"
                    orientation="horizontal"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gridGap: "1em",
                      margin: "1em 0em",
                      padding: "0em",
                    }}
                  >
                    <ToggleButton
                      value="Friendly"
                      sx={bodySmallWith({ margin: "1em 1em 1em 0em" })}
                    >
                      Friendly
                    </ToggleButton>

                    <ToggleButton
                      value="Withdrawn"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Withdrawn
                    </ToggleButton>

                    <ToggleButton
                      value="Cooperative"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Cooperative
                    </ToggleButton>

                    <ToggleButton
                      value="Uncooperative"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Uncooperative
                    </ToggleButton>

                    <ToggleButton
                      value="Empathetic"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Empathetic
                    </ToggleButton>

                    <ToggleButton
                      value="Indifferent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Indifferent
                    </ToggleButton>

                    <ToggleButton
                      value="Respectful"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Respectful
                    </ToggleButton>

                    <ToggleButton
                      value="Disrespectful"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Indifferent
                    </ToggleButton>

                    <ToggleButton
                      value="Responsible"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Responsible
                    </ToggleButton>

                    <ToggleButton
                      value="Irresponsible"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Irresponsible
                    </ToggleButton>

                    <ToggleButton
                      value="Communicative"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Communicative
                    </ToggleButton>

                    <ToggleButton
                      value="Non-communicative"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Non-communicative
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Academically Assessment
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gridGap: "1em",
                      margin: "1em 0em",
                      padding: "0em",
                    }}
                    fullWidth
                    id="handleacademic"
                    value={academic}
                    onChange={handleAcademic}
                    aria-label="text alignment"
                    orientation="horizontal"
                  >
                    <ToggleButton
                      value="Curious"
                      sx={bodySmallWith({ margin: "1em 1em 1em 0em" })}
                    >
                      Curious
                    </ToggleButton>

                    <ToggleButton
                      value="Persistent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Persistent
                    </ToggleButton>

                    <ToggleButton
                      value="Organized"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Organized
                    </ToggleButton>

                    <ToggleButton
                      value="Creative"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Creative
                    </ToggleButton>

                    <ToggleButton
                      value="orginial thinker"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      orginial thinker
                    </ToggleButton>

                    <ToggleButton
                      value="critical thinker"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      critical thinker
                    </ToggleButton>

                    <ToggleButton
                      value="Independent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Independent
                    </ToggleButton>

                    <ToggleButton
                      value="Detail-oriented"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Detail-oriented
                    </ToggleButton>

                    <ToggleButton
                      value="Disinterested"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Disinterested
                    </ToggleButton>

                    <ToggleButton
                      value="Inconsistent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Inconsistent
                    </ToggleButton>

                    <ToggleButton
                      value="Disorganized"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Disorganized
                    </ToggleButton>

                    <ToggleButton
                      value="Dependent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Dependent
                    </ToggleButton>

                    <ToggleButton
                      value="Careless"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Careless
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>

            <div align="center">
              <CardContent>
                <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                  Emotionally Assessment
                </Typography>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup
                    fullWidth
                    id="handleemotional"
                    value={emotional}
                    onChange={handleEmotional}
                    aria-label="text alignment"
                    orientation="horizontal"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gridGap: "1em",
                      margin: "1em 0em",
                      padding: "0em",
                    }}
                  >
                    <ToggleButton
                      value="Empathetic"
                      sx={bodySmallWith({ margin: "1em 1em 1em 0em" })}
                    >
                      Empathetic
                    </ToggleButton>

                    <ToggleButton
                      value="Resilient"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Resilient
                    </ToggleButton>

                    <ToggleButton
                      value="Optimistic"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Optimistic
                    </ToggleButton>

                    <ToggleButton
                      value="Reflective"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Reflective
                    </ToggleButton>

                    <ToggleButton
                      value="Expressive"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Expressive
                    </ToggleButton>

                    <ToggleButton
                      value="Imbalanced"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Imbalanced
                    </ToggleButton>

                    <ToggleButton
                      value="Insensitive"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Insensitive
                    </ToggleButton>

                    <ToggleButton
                      value="Fragile"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Fragile
                    </ToggleButton>

                    <ToggleButton
                      value="Pessimistic"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Pessimistic
                    </ToggleButton>

                    <ToggleButton
                      value="Reactive"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Reactive
                    </ToggleButton>

                    <ToggleButton
                      value="Reticent"
                      sx={bodySmallWith({ margin: "1em" })}
                    >
                      Reticent
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </CardContent>
            </div>
            <Box sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
              <Container
                maxWidth="sm"
                sx={{ px: "56px", py: "16px", background: "#ffffff" }}
              >
                <Button
                  onClick={() => onClickSubmit(proper)}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    px: "24px",
                    py: "16px",
                    borderRadius: "8px",
                    ...titleMediumWith({ color: "#ffffff" }),
                  }}
                  fullWidth
                >
                  Continue
                </Button>
              </Container>
            </Box>
          </Container>
        </Box>
      </div>
      )}
    </>
  );
};
export default ReportGoals;
