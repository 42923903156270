import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Preferences } from "@capacitor/preferences";
import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";

const LessonGoals = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/subtopics`;
  const [data, setData] = useState([]);

  const [goal, setGoal] = React.useState("Practice");
  const [level, setLevel] = React.useState("Beginner");
  const [time, setTime] = React.useState("45min");
  const [activity, setActivity] = React.useState("groupWork");

  const [worksheet, setWorksheet] = React.useState("true");

  const [assignment, setAssignment] = React.useState("inClass");

  const handleGoal = (event, newGoal) => {
    if (newGoal !== null) {
      setGoal(newGoal);
    }
  };

  const handleLevel = (event, newLevel) => {
    if (newLevel !== null) {
      setLevel(newLevel);
    }
  };
  const handleTime = (event, newTime) => {
    if (newTime !== null) {
      setTime(newTime);
    }
  };

  const handleActivity = (event, newActivity) => {
    if (newActivity !== null) {
      setActivity(newActivity);
    }
  };

  const handleWorksheet = (event, newActivity) => {
    if (newActivity !== null) {
      setWorksheet(newActivity);
    }
  };
  const handleAssignment = (event, newAssignment) => {
    if (newAssignment !== null) {
      setAssignment(newAssignment);
    }
  };

  const checkLocalStorage = async (store, skip = 0) => {
    if (!(await Preferences.get({ key: store })).value) {
      if (!skip) {
        navigate(`/create-lesson/curriculum`);
      }
      return (await Preferences.get({ key: store })).value;
    }
  };

  useEffect(() => {
    const init = async () => {
      await checkLocalStorage("task");
      await checkLocalStorage("sector");
      await checkLocalStorage("grade");
      await checkLocalStorage("subject");
      (await checkLocalStorage("subtopic", 1)) || "";
    };

    init();
  }, []);

  if (!idToken) {
    return <Navigate to="/" />;
  }

  const onClickSubmit = async () => {
    const subtopic = (await checkLocalStorage("subtopic", 1)) || "";
    navigate("/create-lesson/output", {
      state: {
        assignment: assignment,
        goal: goal,
        level: level,
        time: time,
        activity: activity,
        subtopic: subtopic,
        worksheet:worksheet
      },
    });
  };

  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <SecondaryAppBar name="Set Goals" showBack={true}></SecondaryAppBar>
      <Box sx={{ pb: 8 }}>
        <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
          <Typography sx={headlineLarge} gutterBottom>
            Goals
          </Typography>
          <Typography sx={bodyLargeWith({ color: "#414752" })} gutterBottom>
            Get tailored lesson plan based on your goals and classroom needs
          </Typography>

          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Goal
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  id="goal"
                  value={goal}
                  exclusive
                  onChange={handleGoal}
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    sx={bodySmallWith({ margin: "1em" })}
                    // sx={{ margin: "1em", fontSize: 10 }}
                    value="Practice"
                  >
                    Practice
                  </ToggleButton>

                  <ToggleButton
                    sx={bodySmallWith({ margin: "1em" })}
                    value="IntroduceNewMaterial"
                  >
                    New Material
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>

          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Level
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  id="level"
                  value={level}
                  onChange={handleLevel}
                  exclusive
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    value="Beginner"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Beginner
                  </ToggleButton>
                  <ToggleButton
                    value="Intermediate"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Intermediate
                  </ToggleButton>
                  <ToggleButton
                    value="Advanced"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Advanced
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>

          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Time
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  id="level"
                  value={time}
                  exclusive
                  onChange={handleTime}
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    value="45min"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    45 Min
                  </ToggleButton>
                  <ToggleButton
                    value="60min"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    60 Min
                  </ToggleButton>
                  <ToggleButton
                    value="90min"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    90 Min
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>

          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Activity
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  value={activity}
                  exclusive
                  onChange={handleActivity}
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    value="groupWork"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Group Work
                  </ToggleButton>
                  <ToggleButton
                    value="game"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Game
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>

          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Generate WorkSheet
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  value={worksheet}
                  exclusive
                  onChange={handleWorksheet}
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    value="true"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value="false"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>


          <div align="center">
            <CardContent>
              <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                Assignment
              </Typography>
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  fullWidth
                  value={assignment}
                  exclusive
                  onChange={handleAssignment}
                  aria-label="text alignment"
                  orientation="horizontal"
                >
                  <ToggleButton
                    value="inClass"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    In Class
                  </ToggleButton>
                  <ToggleButton
                    value="homework"
                    sx={bodySmallWith({ margin: "1em" })}
                  >
                    HomeWork
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </CardContent>
          </div>
          <Box sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
            <Container
              maxWidth="sm"
              sx={{ px: "56px", py: "16px", background: "#ffffff" }}
            >
              <Button
                onClick={() => onClickSubmit()}
                variant="contained"
                sx={{
                  boxShadow: "none",
                  px: "24px",
                  py: "16px",
                  borderRadius: "8px",
                  ...titleMediumWith({ color: "#ffffff" }),
                }}
                fullWidth
              >
                Continue
              </Button>
            </Container>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default LessonGoals;
