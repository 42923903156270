import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styles from "../../components/ButtonStyle.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import SecondaryAppBar from "../../components/todo/SecondaryAppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import LoadingState from "../../components/LoadingState";
import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import { Browser } from "@capacitor/browser";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Preferences } from "@capacitor/preferences";
import {
  bodyLargeWith,
  bodyMediumWith,
  bodySmallWith,
  headlineLarge,
  titleMediumWith,
} from "../../setup/styles-text";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const GradeGoals = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const idToken = getAccessTokenSilently();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/`;
  const [data, setData] = useState([]);
  const [goal, setGoal] = React.useState("0-100");
  const [subtopic, setSubtopic] = React.useState("");
  const [level, setLevel] = React.useState("Yes");
  const [time, setTime] = React.useState("Highly Motivated");
  const [activity, setActivity] = React.useState("Yes");

  const [proper, setProper] = React.useState("");

  const [isLoading, setLoading] = useState(false);

  const [assignment, setAssignment] = React.useState("inClass");
  const [gradedata, setGradedata] = useState(false);

  const handleSubtopic = (event, newGoal) => {
    if (newGoal !== null) {
      setSubtopic(newGoal);
    }
  };
  const handleGoal = (event, newGoal) => {
    if (newGoal !== null) {
      setGoal(newGoal);
    }
  };

  const handleLevel = (event, newLevel) => {
    if (newLevel !== null) {
      setLevel(newLevel);
    }
  };
  const handleTime = (event, newTime) => {
    if (newTime !== null) {
      setTime(newTime);
    }
  };

  const handleActivity = (event, newActivity) => {
    if (newActivity !== null) {
      setActivity(newActivity);
    }
  };
  const handleAssignment = (event, newAssignment) => {
    if (newAssignment !== null) {
      setAssignment(newAssignment);
    }
  };

  const checkLocalStorage = async (store) => {
    if (!(await Preferences.get({ key: store })).value) {
      navigate(`/create-grading/curriculum`);
      return (await Preferences.get({ key: store })).value;
    }
  };

  useEffect(() => {
    const init = async () => {
      await checkLocalStorage("task");
      await checkLocalStorage("sector");
      await checkLocalStorage("grade");
      await checkLocalStorage("subject");
    };

    init();
    setProper(props);
  }, []);

  if (!idToken) {
    return <Navigate to="/" />;
  }
  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.MuiToggleButton-root": {
      color: "#414752",
      backgroundColor: "#FFFFFF",
      borderRadius: "1em",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#569EF9",
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "1em 1em 1em 1em",
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      border: "1px solid #DDE2F0",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      borderRadius: ".5em .5em .5em .5em",
    },
  }));
  const theme = createTheme({
    palette: {
      text: {
        primary: "#4791EB",
      },
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onClickSubmit = async (proper) => {
    var token = await getAccessTokenSilently();
    const imagedata = proper.imgd;
    setLoading(true);
    const formData = new FormData();

    formData.append("file", imagedata);
    //show loader

    fetch(
      url +
        `grading?topic=${
          (await Preferences.get({ key: "topic" })).value
        }&scale=${goal}&feedback=${level}&characteristic=${time}&disability=${activity}&studentid=123`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {
        "Content-Type": "multipart/form-data",
      },
    )
      .then((response) => response.json())
      .then((result) => {
        // show results
        setGradedata(result);

        console.log("Success:", result);
      })
      .then((d) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openFeedback = () => {
    Browser.open({
      url: "https://us14.list-manage.com/survey?u=d8fbc33c65fe704844c546dd2&id=7451740cea",
    });
    Browser.addListener("browserFinished", () => {
      navigate("/");
    });
  };

  const onClick = (identifier) => {
    if (identifier === "uploadgradeimage") {
      window.location.reload();
    } else if (identifier === "finish") {
      // open feedback
      openFeedback();
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const items = [
    "Overall Grade",
    "Compliance with instructions",
    "Organization",
    "Clarity and Coherence",
    "Length of answer",
    "Language and Mechanics",
    "Originality and Creativity",
  ];

  return isLoading ? ( //Checkif if is loading
    <LoadingState name=""></LoadingState>
  ) : (
    <>
      {gradedata && gradedata.data && gradedata.data["Overall Grade"] ? (
        <div>
          <SecondaryAppBar name="" showBack={true}></SecondaryAppBar>
          <Box sx={{ pb: 8 }}>
            <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
              <Card sx={{ marginBottom: "2em" }}>
                <CardContent
                  style={{
                    background: "rgb(202,226,255)",
                    backgroundPosition: "right bottom, right bottom, left top",
                    backgroundRepeat: "no-repeat, no-repeat, no-repeat",
                    backgroundImage:
                      "url(/strip1.png), url(/strip2.png) , linear-gradient(90deg, rgba(202,226,255,1) 0%, rgba(255,225,239,1) 100%)",
                  }}
                  sx={{ alignItems: "center", fontSize: ".7em" }}
                >
                  <Typography
                    sx={bodyLargeWith({
                      textAlign: "left !important",
                      fontSize: "30px",
                      color: "#569EF9",
                    })}
                  >
                    Grade: {gradedata.data["Overall Grade"].grade}
                  </Typography>
                  {items.map((item) => (
                    <div sx={{}}>
                      <Typography
                        sx={bodyMediumWith({
                          textAlign: "left !important",
                          color: "#656565",
                          mt: ".5em",
                        })}
                        style={{ display: "inline-block" }}
                      >
                        {item} ---- &nbsp;
                        <Typography
                          sx={bodyMediumWith({
                            textAlign: "left !important",
                            color: "#2A1F1D",
                          })}
                          style={{ display: "inline-block" }}
                        >
                          {"    " + gradedata.data[item].grade}{" "}
                        </Typography>
                      </Typography>
                      <br></br>
                    </div>
                  ))}
                </CardContent>
              </Card>
              {items.map((item) => (
                <>
                  <Box sx={{ marginBottom: "1em" }}>
                    <div align="left" sx={{ marginTop: "1em" }}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                          <Typography
                            sx={bodyLargeWith({
                              color: "#191C21",
                              fontWeight: 700,
                            })}
                          >
                            {item} &nbsp;( Grade : {gradedata.data[item].grade}{" "}
                            )
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {level == "Yes" ? (
                              <>
                                <Typography
                                  align="left"
                                  variant="body1"
                                  sx={bodyLargeWith({
                                    color: "#191C21",
                                    fontWeight: 600,
                                  })}
                                >
                                  Feedback: <br></br>
                                </Typography>
                                <Typography align="left" variant="body1">
                                  {gradedata.data[item].gradereason}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                            <Typography
                              align="left"
                              variant="body1"
                              sx={bodyLargeWith({
                                color: "#191C21",
                                fontWeight: 600,
                              })}
                            >
                              Tips: <br></br>
                            </Typography>
                            <Typography align="left" variant="body1">
                              {gradedata.data[item].tips}
                            </Typography>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Box>
                </>
              ))}

              <Box
                sx={{
                  position: "fixed",
                  left: 0,
                  bottom: "0",
                  width: "100%",
                }}
              >
                <Container
                  maxWidth="sm"
                  sx={{ px: "56px", py: "16px", background: "#ffffff" }}
                  textAlign="center"
                >
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    sx={{ pt: "2em" }}
                    inline
                  >
                    <Button
                      onClick={() => onClick("finish")}
                      variant="outlined"
                      sx={{
                        boxShadow: "none",
                        py: "1em",
                        mx: "10px",
                        borderRadius: "8px",
                        ...titleMediumWith({ color: "#005FAF" }),
                        width: "40%",
                        backgroundColor: "#FFFFFF",
                        borderColor: "#005FAF",
                      }}
                    >
                      Finish
                    </Button>
                    <Button
                      onClick={() => onClick("uploadgradeimage")}
                      variant="contained"
                      sx={{
                        boxShadow: "none",
                        py: "1em",
                        mx: "10px",
                        borderRadius: "8px",
                        ...titleMediumWith({ color: "#ffffff" }),
                        width: "40%",
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Container>
              </Box>
            </Container>
          </Box>
        </div>
      ) : (
        <div>
          <SecondaryAppBar
            name="Set Goals for Grading"
            showBack={true}
          ></SecondaryAppBar>
          <Box sx={{ pb: 8 }}>
            <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
              <Typography sx={headlineLarge} gutterBottom>
                Goals
              </Typography>
              <Typography sx={bodyLargeWith({ color: "#414752" })} gutterBottom>
                Grade student work based on your grading style & student
                characteristics
              </Typography>

              <div align="center">
                <CardContent>
                  <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                    Scale
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <ToggleButtonGroup
                      fullWidth
                      id="goal"
                      value={goal}
                      exclusive
                      onChange={handleGoal}
                      aria-label="text alignment"
                      orientation="horizontal"
                      sx={{ ml: "-2em" }}
                    >
                      <ToggleButton
                        value="0-100"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        0-100
                      </ToggleButton>

                      <ToggleButton
                        value="Letter"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        Letter
                      </ToggleButton>

                      <ToggleButton
                        value="Pass/Fail"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        Pass/Fail
                      </ToggleButton>
                      <ToggleButton
                        value="Standardized"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        Standardized
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </CardContent>
              </div>

              <div align="center">
                <CardContent>
                  <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                    Feedback
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <ToggleButtonGroup
                      fullWidth
                      id="level"
                      value={level}
                      exclusive
                      onChange={handleLevel}
                      aria-label="text alignment"
                      orientation="horizontal"
                    >
                      <ToggleButton
                        value="Yes"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        Yes
                      </ToggleButton>

                      <ToggleButton
                        value="No"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </CardContent>
              </div>

              <div align="center">
                <CardContent>
                  <Typography sx={bodyMediumWith({ color: "#201A17" })}>
                    Learning Disabilities
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <ToggleButtonGroup
                      fullWidth
                      value={activity}
                      exclusive
                      onChange={handleActivity}
                      aria-label="text alignment"
                      orientation="horizontal"
                    >
                      <ToggleButton
                        value="Yes"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        Yes
                      </ToggleButton>

                      <ToggleButton
                        value="No"
                        sx={bodySmallWith({ margin: "1em" })}
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </CardContent>
              </div>
              <Box
                sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}
              >
                <Container
                  maxWidth="sm"
                  sx={{ px: "56px", py: "16px", background: "#ffffff" }}
                >
                  <Button
                    onClick={() => onClickSubmit(proper)}
                    variant="contained"
                    sx={{
                      boxShadow: "none",
                      px: "24px",
                      py: "16px",
                      borderRadius: "8px",
                      ...titleMediumWith({ color: "#ffffff" }),
                    }}
                    fullWidth
                  >
                    Continue
                  </Button>
                </Container>
              </Box>
            </Container>
          </Box>
        </div>
      )}
    </>
  );
};
export default GradeGoals;
